import React, { FC } from 'react';
import { graphql } from 'gatsby';

import Layout from 'gatsby-theme-husky/src/layout/Layout';
import BodyRenderer from 'gatsby-theme-husky/src/common/BodyRenderer';
import SimpleBanner from 'gatsby-theme-husky/src/components/SimpleBanner';
import TextSection from 'components/TextSection';
import ArticleCardsList from 'components/ArticleCardsList';
import InfoBanner from 'components/InfoBanner';
import WideBanner from 'components/WideBanner';
import SignUp from 'components/SignUp';
import DescriptionLinkVideo from 'common/DescriptionLinkVideo';
import { ArticleListingPageTypes } from '@shared/types/umbraco/content';
import PromoForm from 'components/PromoForm';
import ImageSection from 'components/ImageSection';
import MediaBanner from 'components/MediaBanner';

const elements = {
  'Text Section': ({ properties }, keyId) => <TextSection key={keyId} {...properties} />,
  'Image Section': ({ properties }, keyId) => <ImageSection key={keyId} {...properties} />,
  'Signup Placeholder': ({ properties }, keyId) => <SignUp key={keyId} {...properties} />,
  'Articles List': ({ properties }, keyId) => <ArticleCardsList key={keyId} {...properties} />,
  'Info Banner': ({ properties }, keyId) => <InfoBanner key={keyId} {...properties} />,
  'Promo Form': ({ properties }, keyId) => <PromoForm key={keyId} {...properties} />,
  'New Media Banner': ({ properties }, keyId) => <MediaBanner key={keyId} {...properties} />,
  'Info Banner Wide': ({ properties }, keyId) => <WideBanner key={keyId} {...properties} />,
  'Description Link Video': ({ properties }, keyId) => (
    <DescriptionLinkVideo key={keyId} {...properties} />
  ),
};

const ArticleListingPage: FC<ArticleListingPageTypes.IProperties> = ({
  pageContext: { breadCrumbs, link, searchUrl, articlesLinks },
  data,
}) => {
  const {
    defaultCompositions,
    seoMetaKeywords,
    seoMetaTitle,
    seoMetaTitleTemplate,
    seoMetaDescription,
    seoExternalHreflangs,
    seoCanonicalUrl,
    seoNoIndex,
    alternateUrls,
    ogImageUrl,
  } = data?.page?.nodes?.[0];
  const body = data?.page?.nodes?.[0]?.body;
  const banner = data?.page?.nodes?.[0]?.banner;
  const { articles } = data;
  const { singupFormBaner, waiSettings } = defaultCompositions || {};
  const imageUrl = ogImageUrl || banner?.[0]?.properties?.image?.fallbackUrl;
  const imageAlt = banner?.[0].properties?.title;

  return (
    <Layout
      {...{
        ...defaultCompositions,
        seoMetaKeywords,
        seoMetaTitle,
        seoMetaTitleTemplate,
        seoNoIndex,
        seoCanonicalUrl,
        seoMetaDescription,
        seoExternalHreflangs,
        skipText: waiSettings?.skipText,
        link,
        searchUrl,
        alternateUrls,
        imageUrl,
        imageAlt,
      }}
    >
      {banner?.length ? <SimpleBanner {...{ ...banner[0].properties, breadCrumbs }} /> : null}
      {body.length ? (
        <div className="article-listing-page__body">
          <BodyRenderer
            bodyData={body}
            bodyStructure={elements}
            bodyItemProps={{ ...{ articles: articles?.nodes, singupFormBaner, articlesLinks } }}
          />
        </div>
      ) : null}
    </Layout>
  );
};

export const query = graphql`
  query allUmbracoArticleListingQuery($articlesLinks: [String], $link: String = "") {
    page: allUmbracoArticleListing(filter: { link: { eq: $link } }) {
      nodes {
        seoMetaTitle
        seoMetaTitleTemplate
        seoMetaDescription
        seoMetaKeywords
        seoNoIndex
        seoExternalHreflangs {
          key
          value
        }
        seoCanonicalUrl {
          url
        }
        alternateUrls {
          lang
          url
          path
        }
        ogImageUrl
        defaultCompositions {
          siteSettings {
            lang
          }
          footer {
            ...FragmentFooter
          }
          header {
            ...FragmentHeader
          }
          siteSettings {
            ...FragmentSiteSettingsComposition
          }
          brandSettings {
            ...FragmentBrandSettingsComposition
          }
          singupFormBaner {
            ...FragmentSignUpBanner
          }
          waiSettings {
            skipText
          }
          warning {
            ...FragmentWarning
          }
          signUpPopup {
            ...FragmentSignUpPopup
          }
          signUpFormPopup {
            ...FragmentSignUpFormPopup
          }
          promoPlatformForm {
            ...FragmentPromoPlatformForm
          }
        }
        banner {
          ...FragmentSimpleBanner
        }
        body {
          ... on TTextSection {
            ...FragmentTextSection
          }
          ... on TNewMediaBanner {
            ...FragmentMediaBanner
          }
          ... on TInfoBannerWide {
            ...FragmentWideBanner
          }
          ... on TInfoBanner {
            ...FragmentInfoBanner
          }
          ... on TPromoForm {
            ...FragmentPromoForm
          }
          ... on TImageSection {
            ...FragmentImageSection
          }
          ... on TArticlesList {
            ...FragmentArticlesList
          }
          ... on TSignupPlaceholder {
            ...FragmentSignUp
          }
          ... on TDescriptionLinkVideo {
            ...FragmentDescriptionLinkVideo
          }
        }
      }
    }
    articles: allArticle(filter: { link: { in: $articlesLinks } }) {
      nodes {
        link
        tags {
          isCategory
          isLabel
          title
        }
        title
        lang
        date
        dateSufix
        image {
          properties {
            image {
              ...FragmentGatsbyProps
              gatsbyImage {
                childImageSharp {
                  fluid(maxWidth: 300) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
            imageAlt
          }
        }
      }
    }
  }
`;

export default ArticleListingPage;
