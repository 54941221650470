import { UmbracoRelatedArticles } from '@shared/types/umbraco/compositions';

export const sortArticleByRelevant = (
  relevantLinksArr: string[] | undefined,
  relatedArticles: UmbracoRelatedArticles.IArticleCard[]
) => {
  if (!relevantLinksArr && relatedArticles) return relatedArticles;

  const result: UmbracoRelatedArticles.IArticleCard[] = [];
  relevantLinksArr?.forEach((elLink) => {
    const node = relatedArticles.find((el) => el.link === elLink);
    if (node) {
      result.push(node);
    }
  });

  return result;
};
