import { useEffect } from 'react';
import { UseFormAndSessionStorage } from './model';

// Custom hook to handle saving input data to session storage and getting this data from session storage to pre-render in input field for forms

const useFormAndSessionStorage: UseFormAndSessionStorage = (
  sessionStorageFieldName,
  saveFieldName,
  setFieldName
) => {
  useEffect(() => {
    if (saveFieldName) {
      const handleFormSubmit = (e: Event) => {
        const form = e.target as HTMLFormElement;

        const saveField = form.querySelector(`input[name="${saveFieldName}"]`);

        if (saveField) {
          const fieldValue = (saveField as HTMLInputElement).value;

          if (fieldValue.trim() === '') {
            return;
          }

          sessionStorage.setItem(sessionStorageFieldName, fieldValue);
        }
      };

      document.addEventListener('submit', handleFormSubmit);

      return () => {
        document.removeEventListener('submit', handleFormSubmit);
      };
    }
  }, [saveFieldName]);

  useEffect(() => {
    if (setFieldName) {
      const setSessionFieldToInput = () => {
        const setField = document.querySelector(`input[name="${setFieldName}"]`);

        if (setField) {
          const storedFieldData = sessionStorage.getItem(sessionStorageFieldName);

          if (storedFieldData) {
            const inputElement = setField as HTMLInputElement;
            inputElement.value = storedFieldData;

            inputElement.focus();
          }
        }
      };

      const storedFieldData = sessionStorage.getItem(sessionStorageFieldName);

      if (storedFieldData) {
        setSessionFieldToInput();
      }
    }
  });
};

export default useFormAndSessionStorage;
