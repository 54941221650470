import { UmbracoRelatedArticles } from '@shared/types/umbraco/compositions';
import { UmbracoControls } from '@shared/types/umbraco/controls';

export const setFeaturedArticleFirst = (
  featuredArticle: UmbracoControls.MultiUrlPicker[],
  articles: UmbracoRelatedArticles.IArticleCard[] = []
) => {
  if (!featuredArticle?.length) return articles;
  const [{ url: firstElemTitle }] = featuredArticle;

  const result = [];

  articles.forEach((el) => {
    if (el.link === firstElemTitle) {
      return result.unshift(el);
    }

    return result.push(el);
  });

  return result;
};
