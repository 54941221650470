import React, { FC, useEffect, useState, useMemo, useRef } from 'react';
import { graphql } from 'gatsby';
import { Container } from 'layout';
import RelatedArticleCard from 'components/RelatedArticleCard';
import Button from 'common/Button';

import useScreenRecognition from 'hooks/useScreenRecognition';
import { setFeaturedArticleFirst } from 'utils/setFeaturedArticleFirst';
import { sortArticleByRelevant } from 'utils/sortByRelevant';
import { ArticleCardsListProps } from './models';
import './ArticleCardsList.scss';

const ArticleCardsList: FC<ArticleCardsListProps> = ({
  featuredArticle,
  limit = 0,
  limitMobile = 0,
  btnTitle = '',
  ariaMore = '',
  articles,
  articlesLinks,
}) => {
  const ref = useRef(null);
  const { isNotMobile } = useScreenRecognition();
  const [toShow, setToShow] = useState<number>(limitMobile);

  const stepLoad = useMemo(() => (isNotMobile ? limit : limitMobile), [isNotMobile]);
  const step = useMemo(
    () =>
      featuredArticle?.length && isNotMobile && articles.length > 1 && stepLoad > 1
        ? toShow - 1
        : toShow,
    [stepLoad, toShow]
  );

  useEffect(() => {
    setToShow(stepLoad);
  }, [stepLoad]);

  const sortedByRelevantArticles = sortArticleByRelevant(articlesLinks, articles);

  const articlesToShow = setFeaturedArticleFirst(featuredArticle, sortedByRelevantArticles);

  const countArticleToShow = useMemo(
    () => (articles.length - step <= 3 ? articles.length : step),
    [toShow]
  );

  const loadMore = () => {
    const lessCountValue = isNotMobile ? 3 : 2;

    const count =
      articles.length - (step + stepLoad) <= lessCountValue ? articles.length : toShow + stepLoad;
    setToShow(count);

    setTimeout(() => {
      ref.current?.children[countArticleToShow]?.focus();
    }, 0);
  };

  return (
    <>
      {articlesToShow?.length ? (
        <div className="article-cards-list" data-testid="article-cards-list">
          <Container fluid>
            <div ref={ref} className="article-cards-list__grid">
              {articlesToShow?.slice(0, countArticleToShow).map((article, index) => {
                const isFull = !!featuredArticle?.length && index === 0;

                return <RelatedArticleCard key={article.title} {...article} isFull={isFull} />;
              })}
            </div>
            {articlesToShow?.length > 0 &&
            btnTitle &&
            countArticleToShow < articlesToShow?.length ? (
              <div className="article-cards-list__more">
                <Button ariaLabel={ariaMore} clickHandler={loadMore} variant="primary">
                  {btnTitle}
                </Button>
              </div>
            ) : null}
          </Container>
        </div>
      ) : null}
    </>
  );
};

export default ArticleCardsList;

export const query = graphql`
  fragment FragmentArticlesList on TArticlesList {
    properties {
      featuredArticle {
        name
        url
      }
      limit
      limitMobile
      btnTitle
      ariaMore
    }
    structure
  }
`;
