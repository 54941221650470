import React, { FC, useRef, useEffect } from 'react';
import { graphql } from 'gatsby';
import { UmbracoPromoForm } from '@shared/types/umbraco';
import PromoFormComponent from '@promo-platform/promo-form';

import GatsbyImage from 'common/GatsbyImage';
import Section from 'common/Section';

import useFormAndSessionStorage from 'hooks/useFormAndSessionStorage/useFormAndSessionStorage';

import '@promo-platform/styles';
import '@fontsource/montserrat/300.css';
import './PromoForm.scss';

const PromoForm: FC<UmbracoPromoForm.IData> = ({
  id,
  url,
  color,
  sectionTheme,
  description,
  image,
  setToFieldDataWithName,
  saveFieldDataWithName,
}) => {
  const sectionRef = useRef(null);

  if (saveFieldDataWithName || setToFieldDataWithName) {
    useFormAndSessionStorage('emailField', saveFieldDataWithName, setToFieldDataWithName);
  }

  useEffect(() => {
    const anchorLink = document.querySelector(`[data-anchor='#${id}']`);

    if (anchorLink) {
      const handleClick = (event) => {
        event.preventDefault();
        sectionRef.current?.scrollIntoView({ block: 'start', behavior: 'smooth' });
      };

      anchorLink.addEventListener('click', handleClick);

      return () => {
        anchorLink.removeEventListener('click', handleClick);
      };
    }
  }, [id]);

  return url && id ? (
    <Section name="promo-form" color={color} intro={description} classes={sectionTheme}>
      {image?.[0]?.properties?.image ? (
        <GatsbyImage
          image={image[0].properties.image}
          alt={image[0].properties.imageAlt}
          objectFit="contain"
          className="promo-form__image"
          fadeIn={false}
        />
      ) : null}
      <div id={id} ref={sectionRef}>
        <PromoFormComponent id={id} url={url} />
      </div>
    </Section>
  ) : null;
};

export const query = graphql`
  fragment FragmentPromoForm on TPromoForm {
    properties {
      url
      id
      color {
        label
      }
      sectionTheme
      image {
        properties {
          image {
            ...FragmentGatsbyProps
            gatsbyImage {
              childImageSharp {
                fluid(maxWidth: 650, quality: 90) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
          imageAlt
        }
      }
      saveFieldDataWithName
      setToFieldDataWithName
      description
    }
    structure
  }
`;

export default PromoForm;
