import React, { FC } from 'react';
import { graphql } from 'gatsby';
import GatsbyImage from 'common/GatsbyImage';
import Section from 'common/Section';
import { DangerouslySetInnerHtml } from 'layout';
import ConditionalWrapper from 'common/ConditionalWrapper/ConditionalWrapper';
import Button from 'common/Button';
import { IImageSection } from './model';
import './ImageSection.scss';

const ImageSection: FC<IImageSection> = ({ image, color, caption, file, link }) => {
  const linkProvider = link?.[0]?.properties || file?.[0]?.properties;

  return (
    <Section name="image" color={color}>
      <>
        <figure>
          <ConditionalWrapper
            condition={Boolean(linkProvider)}
            wrapper={(children) => {
              const linkObject = linkProvider.link?.length
                ? linkProvider.link[0]
                : linkProvider.link;

              return (
                <Button
                  variant="icon"
                  to={linkObject.url}
                  target={linkObject?.target}
                  ariaLabel={linkProvider?.ariaLabel}
                >
                  {children}
                </Button>
              );
            }}
          >
            <GatsbyImage
              image={image[0].properties.image}
              alt={image[0].properties.imageAlt}
              className="image-section__img"
            />
          </ConditionalWrapper>
          {caption && (
            <DangerouslySetInnerHtml
              element="figcaption"
              className="image-section__caption"
              html={caption}
            />
          )}
        </figure>
      </>
    </Section>
  );
};

export default ImageSection;

export const query = graphql`
  fragment FragmentImageSection on TImageSection {
    properties {
      image {
        properties {
          image {
            ...FragmentGatsbyProps
            gatsbyImage {
              childImageSharp {
                fluid(maxWidth: 1200) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          imageAlt
        }
      }
      color {
        label
      }
      caption
      link {
        properties {
          ariaLabel
          link {
            target
            url
          }
        }
      }
      file {
        properties {
          ariaLabel
          link {
            url
          }
        }
      }
    }
    structure
  }
`;
